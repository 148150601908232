import React from 'react';
import * as styles from './ApplePayPage.module.css';

import ApplePayLogo from '../../images/apple-pay/apple-pay-logo-dark-bg.svg';
import RockerLogo from '../../images/rocker-logo-dark-bg.svg';

import Screenshot1 from '../../images/apple-pay/screenshot1.png';
import Screenshot2 from '../../images/apple-pay/screenshot2.png';
import WalletIcon from '../../images/apple-pay/ios-wallet-icon.png';

export default function ApplePayPage() {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.logoImages}>
          <a href="/">
            <img
              src={RockerLogo}
              className={styles.rockerLogo}
              alt="Rocker Logo"
            />
          </a>
          <div className={styles.logoImageDivider} />
          <img
            src={ApplePayLogo}
            className={styles.applePayLogo}
            alt="Apple Pay Logo"
          />
        </div>
        <h1>Betala snabbt och säkert</h1>
      </header>
      <main className={styles.main}>
        <section className={styles.section}>
          <img src={Screenshot1} alt="Bild på Wallet-appen" />
          <div className={styles.textSection}>
            <h2>Så lägger du till ditt kort</h2>
            <p>Lägg till ditt kort till Apple Pay. Kom igång enkelt.</p>

            <div className={styles.flexRow}>
              <div>
                <h3>iPhone</h3>
                <p>
                  Öppna Wallet-appen på din iPhone och tryck på plus-symbolen.
                </p>
              </div>
              <img src={WalletIcon} alt="Apple Wallet Icon" />
            </div>

            <h3>Apple Watch</h3>
            <p>Öppna Wallet-appen på din iPhone och tryck på plus-symbolen.</p>
          </div>
        </section>
        <section className={styles.section}>
          <img
            src={Screenshot2}
            className={styles.screenshot2}
            alt="Bild Apple Pay-appen"
          />
          <div className={styles.textSection}>
            <h2>Så betalar du</h2>
            <p>
              Apple Pay är det enklaste sättet att betala i butiker, appar och
              online med din iPhone, Apple Watch, iPad och Mac. Att spendera
              pengar borde inte betyda mer spenderad tid vid kassan eller på att
              knappa in kortnumret online eller i din app. Med Apple Pay kan du
              betala snabbt med enheterna du använder varje dag.
            </p>
          </div>
        </section>
      </main>
    </>
  );
}
