import React from 'react';
import propTypes from 'prop-types';

import ApplePayPage from '@src/components/apple-pay-components/ApplePayPage';
import Footer from '@src/components/footer';

const ApplePay = () => {
  return (
    <>
      <ApplePayPage />
      <Footer minimal />
    </>
  );
};

ApplePay.propTypes = {
  data: propTypes.object,
  location: propTypes.object,
};

export default ApplePay;
