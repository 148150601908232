// extracted by mini-css-extract-plugin
export var applePayLogo = "ApplePayPage-module--applePayLogo--8fbce";
export var flexRow = "ApplePayPage-module--flexRow--49995";
export var header = "ApplePayPage-module--header--fa825";
export var logoImageDivider = "ApplePayPage-module--logoImageDivider--24872";
export var logoImages = "ApplePayPage-module--logoImages--c805b";
export var main = "ApplePayPage-module--main--fd2ce";
export var rockerLogo = "ApplePayPage-module--rockerLogo--4a23c";
export var screenshot2 = "ApplePayPage-module--screenshot2--6d73b";
export var section = "ApplePayPage-module--section--38b13";
export var textSection = "ApplePayPage-module--textSection--82909";